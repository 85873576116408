<template>
  <InlineLoader v-if="loading" />

  <div v-else>
    <ReportEventListItem
      v-for="(event, i) in eventsSortedByTimestamp"
      :key="i"
      :event="event"
      :editable="editable"
      :selectable="selectable"
      @edit="onEdit(event)"
      @remove="onRemove(event)">
    </ReportEventListItem>

    <Paragraph
      v-if="events.length === 0"
      class="has-text-grey"
      :text="$t('report.events.none')">
    </Paragraph>
  </div>
</template>

<script>
import ReportEventListItem from './ReportEventListItem.vue'

export default {
  components: {
    ReportEventListItem
  },

  props: {
    events: {
      type: Array,
      default: () => []
    },

    editable: {
      type: Boolean,
      default: false
    },

    selectable: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    eventsSortedByTimestamp () {
      return this.events.slice().sort((a, b) => this.$dayjs(a.timestamp).toDate().getTime() - this.$dayjs(b.timestamp).toDate().getTime())
    }
  },

  methods: {
    onEdit (event) {
      this.$emit('edit', event)
    },

    onRemove (event) {
      this.$emit('remove', event)
    }
  }
}
</script>
