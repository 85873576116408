<template>
  <div ref="list-item" class="report-event-list-item" :class="classes" @click="onClick">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item has-text-centered">
          <div>
            <div v-if="occurredAt !== null">
              {{ occurredAt }}
            </div>

            <div>
              <img :src="icon" />
            </div>

            <small>{{ title }}</small>
          </div>
        </div>
      </div>

      <div class="level-item">
        <div class="event-content">
          <div class="level is-mobile is-marginless">
            <div class="level-left location">
              <span v-if="event.poiName !== null">
                {{ event.poiName }}
              </span>

              <span v-else-if="event.coordinate !== null">
                {{ coordinate.lat }}, {{ coordinate.lng }}
              </span>
            </div>

            <div v-if="event.reportedBy !== null" class="level-right">
              <ProfilePicture
                :user-id="Number(event.reportedBy.userId)"
                class="push-right-xs"
                size="x-small"
                round>
              </ProfilePicture>
              {{ event.reportedBy.name }}
            </div>
          </div>

          <BadgeList v-if="event.games.length > 0">
            <Badge v-for="(game, i) in event.games" :key="i">
              {{ game.count }} {{ game.animalName }}
            </Badge>
          </BadgeList>

          <div v-if="event.comment.length > 0">
            <Divider class="divider" />
            <Paragraph
              class="is-marginless"
              :text="event.comment">
            </Paragraph>
          </div>
        </div>
      </div>

      <div v-if="canEdit" class="level-right">
        <ButtonGroup>
          <Button
            size="small"
            icon="icon-edit-2"
            :tooltip="$t('general.edit')"
            @click="onEdit">
          </Button>

          <Button
            size="small"
            type="danger"
            icon="icon-trash"
            :tooltip="$t('general.delete')"
            :loading="event.removing"
            @click="onRemove">
          </Button>
        </ButtonGroup>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  props: {
    event: {
      type: Object,
      default: () => {}
    },

    editable: {
      type: Boolean,
      default: true
    },

    selectable: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      ignoreSelectionEvent: false
    }
  },

  computed: {
    ...mapGetters({
      userId: 'auth/getUserId',
      report: 'report/getReport',
      hunt: 'report/getHunt',
      selectedEvent: 'report/events/getSelectedEvent'
    }),

    icon () {
      return getMarkerTypeIconUrl(this.event.type)
    },

    title () {
      const titles = {
        kill: this.$i18n.t('report.events.killShort'),
        observation: this.$i18n.t('report.events.observationShort'),
        after_search: this.$i18n.t('report.events.afterSearchShort')
      }
      return titles[this.event.type]
    },

    occurredAt () {
      if (this.event.useTimeInTimestamp && this.event.timestamp !== null) {
        return this.$dayjs(this.event.timestamp).format('HH:mm')
      }

      return null
    },

    coordinate () {
      return {
        lat: this.event.coordinate.lat.toFixed(4),
        lng: this.event.coordinate.lng.toFixed(4)
      }
    },

    isSelected () {
      return this.selectedEvent === this.event
    },

    classes () {
      return {
        'is-clickable': this.selectable,
        'selected': this.isSelected
      }
    },

    canEdit () {
      if (this.editable) {
        return this.isOwner || this.isHunter || this.isAdmin || this.isHuntLeader
      }

      return false
    },

    isOwner () {
      return this.event.createdBy === this.userId
    },

    isAdmin () {
      return this.report !== null ? this.report.createdBy === this.userId : false
    },

    isHunter () {
      return this.event.reportedBy !== null ? this.event.reportedBy.userId === this.userId : false
    },

    isHuntLeader () {
      return this.hunt !== null ? this.hunt.huntLeaderUserId === this.userId : false
    }
  },

  watch: {
    selectedEvent () {
      if (this.isSelected && !this.ignoreSelectionEvent) {
        this.$refs['list-item'].scrollIntoView()
      }

      this.ignoreSelectionEvent = false
    }
  },

  methods: {
    onClick () {
      if (this.selectable) {
        this.select()
      }
    },

    onEdit () {
      this.edit()
    },

    onRemove () {
      this.$emit('remove')
    },

    edit () {
      this.$emit('edit')
    },

    select () {
      this.$store.commit('report/events/selectEvent', this.isSelected ? null : this.event)
      this.ignoreSelectionEvent = true
    }
  }
}
</script>

<style lang="scss" scoped>
.report-event-list-item {
  padding: 1rem;
  border: 1px solid lightgrey;
  border-radius: 4px;
  margin-bottom: 1rem;

  .divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .event-content {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .location {
      font-weight: 600;
    }
  }

  &:hover {
    background-color: rgb(250, 250, 250);
  }

  &.selected {
    border-color: #eb914e;
  }
}
</style>
