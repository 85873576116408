<template>
  <InlineLoader v-if="loading" />

  <div v-else>
    <div v-if="isGrid" class="columns is-multiline">
      <ReportParticipantListItem
        v-for="participant in visibleParticipants"
        :key="participant.id"
        :participant="participant"
        :editable="editable"
        class="column is-half"
        @remove="onRemove(participant)">
      </ReportParticipantListItem>
    </div>

    <div v-else>
      <ReportParticipantListItem
        v-for="participant in visibleParticipants"
        :key="participant.id"
        :participant="participant"
        :editable="editable"
        class="column is-half"
        @remove="onRemove(participant)">
      </ReportParticipantListItem>
    </div>

    <Paragraph
      v-if="participants.length === 0"
      class="has-text-grey"
      :text="$t('report.participants.none')">
    </Paragraph>
  </div>
</template>

<script>
import ReportParticipantListItem from './ReportParticipantListItem.vue'

export default {
  components: {
    ReportParticipantListItem
  },

  props: {
    participants: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    },

    display: {
      type: String,
      default: 'list'
    },

    editable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isGrid () {
      return this.display === 'grid'
    },

    visibleParticipants () {
      return this.participants
    }
  },

  methods: {
    onRemove (participant) {
      this.$emit('remove', participant)
    }
  }
}
</script>
