<template>
  <div class="level is-mobile user">
    <div class="level-left">
      <div class="level-item">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <ProfilePicture :userId="Number(participant.userId)" size="small" round />
            </div>

            <div class="level-item">
              <p class="user-name">{{ participant.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="canEdit" class="level-right">
      <div class="level-item">
        <Icon
          class="is-clickable has-text-danger"
          name="icon-x-circle"
          @click="onRemove">
        </Icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    participant: {
      type: Object,
      required: true
    },

    editable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canEdit () {
      return this.editable ? this.isOwner || this.isAdmin || this.isHuntLeader : false
    },

    isOwner () {
      const userId = this.$store.getters['auth/getUserId']
      return this.participant.createdBy === userId
    },

    isAdmin () {
      const userId = this.$store.getters['auth/getUserId']
      const report = this.$store.getters['report/getReport']
      return report !== null ? report.createdBy === userId : false
    },

    isHuntLeader () {
      const userId = this.$store.getters['auth/getUserId']
      const hunt = this.$store.getters['report/getHunt']
      return hunt !== null ? hunt.huntLeaderUserId === userId : false
    }
  },

  methods: {
    onRemove () {
      this.$emit('remove')
    }
  }
}
</script>

<style scoped>
.user {
  padding: 10px;
}

.user .user-name {
  margin-bottom: 0;
}

.level {
  margin-bottom: 0;
}
</style>
