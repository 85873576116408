<template>
  <div>
    <div class="columns">
      <div class="column is-half">
        <FormField :label="$t('report.startedAt')">
          {{ startsAt }}
        </FormField>
      </div>

      <div class="column is-half">
        <FormField :label="$t('report.endedAt')">
          {{ endsAt }}
        </FormField>
      </div>
    </div>

    <FormField :label="$t('report.manHours')">
      {{ manHours }} h
    </FormField>

    <FormField :label="$t('report.comment')">
      <div v-if="comment" class="text-is-preformatted">{{ comment }}</div>

      <Paragraph
        v-else
        :text="$t('report.commentNone')"
        class="has-text-grey">
      </Paragraph>
    </FormField>

    <FormField :label="$t('report.events.title')">
      <ReportEventList
        :events="events"
        :editable="false"
        :selectable="true">
      </ReportEventList>
    </FormField>

    <FormField :label="$t('report.participants.title')">
      <ReportParticipantList
        display="list"
        :participants="participants"
        :editable="false">
      </ReportParticipantList>
    </FormField>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ReportEventList from '../shared/ReportEventList.vue'
import ReportParticipantList from '../shared/ReportParticipantList.vue'

export default {
  components: {
    ReportParticipantList,
    ReportEventList
  },

  computed: {
    ...mapGetters({
      comment: 'report/getComment',
      events: 'report/events/getAll',
      participants: 'report/participants/getAll',
      manHours: 'report/getHours'
    }),

    startsAt () {
      const startsAt = this.$store.getters['report/getStartsAt']
      return this.$dayjs(startsAt).format('YYYY-MM-DD HH:mm')
    },

    endsAt () {
      const endsAt = this.$store.getters['report/getEndsAt']
      return this.$dayjs(endsAt).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>
